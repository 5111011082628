@import "../../style/mixins/index.scss";

.tips-box {
  @include flex-box-v;
  background: #ffffff;
  overflow: hidden;
}
.tips-img {
  width: 117px;
  height: 117px;
  margin-top: 49px;
}
.tips-text {
  font-size: 19px;
  font-weight: bold;
  color: #333333;
  margin-top: 17px;
  margin-bottom: 38px;
}
.tips-button {
  @include linear-button(262px, 44px);
  margin-bottom: 40px;
}
.fab-groups {
  @include flex-box-v(flex-end);
  position: fixed;
  bottom: 53px;
  right: 15px;
  z-index: 8;
}