@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

.address-empty-box {
  margin-top: 141px;
}
.address-list {
  background: #ffffff;
  overflow: hidden;
}
.address-item {
  @include flex-box;
  padding: 18px 20px 13px 15px;
  background: #ffffff;
  &:not(:last-child) {
    margin-bottom: 13px;
  }
  &:first-child {
    margin-bottom: 0;
    border-bottom: 13px solid #F6F6F6;
  }
}
.edit-png {
  width: 17px;
  height: 17px;
  align-self: flex-start;
}
// address-item-box
@mixin address-item-title() {
  font-size: $title-size;
  color: #262626;
}
.address-item-info-box {
  flex: 1;
}
.address-item-top {
  @include flex-box;
  margin-bottom: 12px;
}
.address-item-name,
.address-item-mobile {
  @include address-item-title;
}
.address-item-name {
  margin-right: $margin-normal;
}
.address-item-mobile {
  margin-right: 18px;
}
.address-item-address-text {
  font-size: 13px;
  color: #999999;
  &:first-child {
    margin-right: 12px;
  }
}
.add-address-button {
  @include linear-button(345px, 44px);
}