@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

.main-wrapper {
  padding: 0 24px;
  background: #ffffff;
  overflow: hidden;
  user-select: none;
}
.poster-board {
  box-sizing: border-box;
  display: block;
  margin: 20px 0;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  padding: 22px 25px 30px;
  position: relative;
}
.poster-board-top {
  @include flex-box;
}
.poster-avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  @include bg-image;
  margin-right: 8px;
}
.poster-top-center {
  flex: 1;
  @include flex-box-v(space-between, flex-start);
  height: 38px;
}
.poster-username {
  font-size: 13px;
  color: #EA952E;
  line-height: 1;
  margin-top: 2.5px;
}
.poster-desc {
  font-size: $content-size;
  color: #313131;
  line-height: 1;
  margin-top: 1.5px;
}
.poster-logo {
  width: 89px;
  height: 32px;
}
.product-bg-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  @include bg-image($bgColor: #FAFAFA, $bgSize: contain);
  margin-top: 19px;
  margin-bottom: 23px;
}
.canvas-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
$bottomHeight: 75px;
.poster-board-bottom {
  @include flex-box();
  height: $bottomHeight;
}
.poster-bottom-left {
  flex: 1;
  height: $bottomHeight;
  @include flex-box-v(space-between, flex-start);
}
.product-title {
  font-size: $content-size;
  font-weight: bold;
  color: #4E4E4E;
}
.product-price {
  font-size: 27px;
  font-weight: bold;
  color: #FF1A1A;
}
.product-price-yuan {
  font-size: $content-size;
}
.product-desc {
  font-size: $content-size;
  color: #9E9E9E;
}
.poster-qrcode {
  width: 75px;
}
.bottom-box {
  @include flex-box-v;
}
.save-tips {
  font-size: $content-size;
  color: #9E9E9E;
  margin-bottom: $margin-alternate;
  margin-top: 10px;
}
.save-button {
  @include linear-button(149px, 42px);
  font-size: 18px;
  font-weight: 400;
  @include flex-box(center);
}