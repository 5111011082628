@import "../../style/mixins/index.scss";

.main-wrapper {
  padding: 0 15px;
  background: #ffffff;
  overflow: hidden;
}
.good-info-box {
  @include flex-box;
  margin-top: 30px;
  margin-bottom: 36px;
}
.good-img {
  @include bg-image($bgColor: #F9F9F9, $bgSize: contain);
  @include size(80px, 80px);
  margin-right: 15px;
  border-radius: 5px;
}
.info-box {
  @include flex-box-v(flex-end, flex-start);
  align-self: flex-end;
}
.good-name {
  @include bold-font;
  font-size: 14px;
  color: #212121;
  margin-bottom: 15px;
}
.post-box {
  background: #F7F7F7;
  padding: 19px 14px 4px;
  border-radius: 9px;
  margin-top: 23px;
}
.msg-textarea {
  width: 100%;
  padding: 0;
  background: #F7F7F7;
  resize: none;
  outline: none;
  border: none;
  line-height: 1.5;
}
.post-button {
  @include linear-button(345px, 44px);
  letter-spacing: 1px;
  margin-top: 23px;
}