@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

.page-box {
  @include flex-box-v;
}
.feature-box {
  flex: 1;
  width: 100%;
  background: #ffffff;
  margin-top: 12px;
}
.save-button {
  @include linear-button(300px, 44px);
  margin-top: 30px;
}
.del-button {
  @include linear-button(300px, 44px);
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #999999;
  color: #999999;
  &:hover {
    background: #ffffff;
  }
}