.common-stepper {
  padding: 0;
}
.common-stepper.am-stepper.showNumber {
  width: 112px;
}
.common-stepper .am-stepper-handler {
  line-height: 25px;
}
.common-stepper .am-stepper-input {
  width: 40px;
  color: #666666;
}
.common-stepper-v2 {
  padding: 0;
}
.common-stepper-v2.am-stepper.showNumber {
  width: 96px;
}
.common-stepper-v2 .am-stepper-handler {
  width: 28px;
  height: 28px;
  line-height: 25px;
}
.common-stepper-v2 .am-stepper-input {
  width: 40px;
  color: #666666;
}
.common-stepper-v2 .am-icon-xxs {
  width: 13px;
  height: 13px;
}
.am-icon.am-icon-loading.am-icon-lg {
  display: none;
}
.custom-loading-icon {
  width: 36px;
  height: 36px;
}