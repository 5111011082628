@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

// bill-address-wrapper
.bill-address-wrapper {
  background: #ffffff;
}
.bill-address-box {
  @include flex-box;
  padding: 18px 15px;
  overflow: hidden;
}
.bill-empty-info-box {
  flex: 1;
}
.add-icon {
  width: 18px;
  height: 22px;
  margin-left: 7px;
  margin-right: 12px;
  align-self: flex-start;
}
.right-arrow {
  width: 6px;
  height: 11px;
  align-self: flex-start;
  margin-top: 4px;
}
.bill-empty-title {
  font-size: $title-size;
  color: #212121;
  margin-top: 3px;
  margin-bottom: 11px;
}
.bill-empty-desc {
  font-size: $desc-size;
  color: #999999;
}
.border-bg {
  width: 100%;
  height: 3px;
  background-image: url('../../images/common/border-bg.png');
  background-repeat: repeat-x;
  background-position-x: 9px;
}
// bill-selected-box
@mixin bill-selected-title() {
  font-size: $title-size;
  color: #262626;
}
.bill-selected-info-box {
  flex: 1;
}
.bill-selected-top {
  @include flex-box;
  margin-bottom: 12px;
}
.bill-selected-name,
.bill-selected-mobile {
  @include bill-selected-title;
}
.bill-selected-name {
  margin-right: $margin-normal;
}
.bill-selected-mobile {
  margin-right: 18px;
}
.bill-selected-address-text {
  font-size: 13px;
  color: #999999;
  &:first-child {
    margin-right: 12px;
  }
}
// main-box
.main-box {
  background: #ffffff;
  padding-left: 15px;
  margin-top: 13px;
}
// bills-wrapper
.bills-wrapper {
  padding-top: 24px;
  overflow: hidden;
}
// message-box
.message-box {
  @include flex-box;
  padding: 20px 15px 15px 0;
  border-top: 1px solid #E5E5E5;
}
.cell-title {
  font-size: 15px;
  color: #212121;
}
.message-textarea {
  flex: 1;
  resize: none;
  border: 0;
  padding: 0;
  margin-left: 24px;
  font-size: $content-size;
  &::-webkit-input-placeholder {
    color: #BFBFBF;
  }
}
@mixin cell-box() {
  background: #ffffff;
  margin-top: 13px;
  padding-left: 15px;
  overflow: hidden;
}
// cell-box1
.cell-box1 {
  @include cell-box;
}
.cell-group {
  @include flex-box(space-between);
  display: inline-flex;
  width: 100%;
}
.cell-group1 {
  margin-top: 18px;
  padding-bottom: 18px;
}
.cell-text-grey {
  font-size: $content-size;
  color: #777777;
  margin-right: $margin-normal;
}
// cell-box2
.cell-box2 {
  @include cell-box;
  padding-top: 10px;
  padding-bottom: 59px;
}
.cell-group2 {
  margin: 10px 0;
}
.cell-text-price {
  font-size: 15px;
  color: $price-color;
  font-weight: bold;
  margin-right: $margin-normal;
}
.cell-text-black {
  font-size: $content-size;
  color: #777777;
  margin-right: $margin-normal;
}
// bill-bar
.bill-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid #DFDFDF;
  background: #ffffff;
  @include flex-box;
  padding: 0 15px;
  z-index: 10;
}
.bill-bar--blank {
  height: 50px;
}
.price-info-box {
  flex: 1;
  font-size: 0;
  margin-left: 2px;
}
.price-desc {
  display: inline-block;
  font-size: $content-size;
  color: #313131;
  font-weight: bold;
}
.price-text {
  display: inline-block;
  font-size: 18px;
  color: $price-color;
  font-weight: bold;
}
.price-text-yuan {
  font-size: $content-size;
}
.submit-button {
  border: 0;
  background: $alternate-color;
  height: 35px;
  line-height: 1;
  border-radius: 18px;
  padding: 10px 18px;
  font-size: $title-size;
  color: #ffffff;
}