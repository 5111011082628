@import "./button.scss";

@mixin area-click($size) {
  &::after {
    content: '';
    position: absolute;
    top: $size;
    bottom: $size;
    left: $size;
    right: $size;
  }
}
@mixin li {
  text-indent: -14px;
  &::before {
    content: '●';
    font-size: 20px;
    color: inherit;
    position: relative;
    top: -4px;
    left: -12px;
  }
}
@mixin bold-font {
  font-family: "PingFang-SC-Bold", "Microsof YaHei";
  font-weight: bold;
}
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin text-overflow-line($lines) {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
@mixin full {
  width: 100%;
  height: 100%;
}
@mixin fix-img($h) {
  width: 100%;
  min-height: $h;
  max-height: $h;
}
@mixin flex-box($justify-content: flex-start, $align-items: center) {
  display: flex;
  flex-direction: row;
  justify-content: $justify-content;
  align-items: $align-items;
}
@mixin flex-box-v($justify-content: flex-start, $align-items: center) {
  display: flex;
  flex-direction: column;
  justify-content: $justify-content;
  align-items: $align-items;
}
@mixin bg-image($bgColor: #ffffff, $bgPosition: center, $bgSize: 100%) {
  background-repeat: no-repeat;
  background-color: $bgColor;
  background-position: $bgPosition;
  background-size: $bgSize;
}