@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

.deduct-wrapper {
  background: #ffffff;
  overflow: hidden;
}
.input-box {
  padding: 0 15px;
}
.input-title {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  margin-top: 21px;
  margin-bottom: $margin-normal;
}
.list-box {
  padding: 0 15px;
}
.list-box-item {
  @include flex-box;
  padding: 17px 0;
  margin-bottom: 3px;
}
.list-box-item-title {
  font-size: $content-size;
  color: #999999;
}
.list-box-item-feature-text {
  font-size: $content-size;
  color: #4F93E3;
  margin-left: 12px;
}
.confirm-button {
  @include linear-button(345px, 44px);
}