@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

.fab-home-box {
  @include flex-box-v(center);
}
.fab-home {
  @include size(23px, 23px);
  display: block;
}
.fab-home-text {
  font-size: 9px;
  color: $alternate-color;
  margin-top: 4px;
}