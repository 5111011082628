@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

$pdl: 24px;
.countdown-bar {
  width: 100%;
  height: 60px;
  background: linear-gradient(270deg,#DBB26F,#EDCC91);
  padding-left: $pdl;
  color: #ffffff;
  @include flex-box-v($justify-content: center, $align-items: flex-start);
}
.countdown-bar-title {
  font-size: 16px;
}
.countdown-bar-desc {
  font-size: 13px;
  margin-top: 6px;
}
// bill-address-wrapper
.bill-address-wrapper {
  background: #ffffff;
}
.bill-address-box {
  @include flex-box;
  padding: 18px 15px 18px $pdl;
  overflow: hidden;
}
.border-bg {
  width: 100%;
  height: 3px;
  background-image: url('../../images/common/border-bg.png');
  background-repeat: repeat-x;
  background-position-x: 9px;
}
// bill-selected-box
@mixin bill-selected-title() {
  font-size: $title-size;
  color: #262626;
}
.bill-selected-info-box {
  flex: 1;
}
.bill-selected-top {
  @include flex-box;
  margin-bottom: 12px;
}
.bill-selected-name,
.bill-selected-mobile {
  @include bill-selected-title;
}
.bill-selected-name {
  margin-right: $margin-normal;
}
.bill-selected-mobile {
  margin-right: 18px;
}
.bill-selected-address-text {
  font-size: 13px;
  color: #999999;
  &:first-child {
    margin-right: 12px;
  }
}
// bill-bar
.bill-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid #DFDFDF;
  background: #ffffff;
  @include flex-box;
  padding: 0 15px;
  z-index: 10;
}
.bill-bar--blank {
  height: 50px;
}
.price-info-box {
  flex: 1;
  font-size: 0;
  margin-left: 2px;
}
.price-info-box-o0 {
  opacity: 0;
}
.price-desc {
  display: inline-block;
  font-size: $content-size;
  color: #313131;
  font-weight: bold;
}
.price-text {
  display: inline-block;
  font-size: 18px;
  color: $price-color;
  font-weight: bold;
}
.price-text-yuan {
  font-size: $content-size;
}
.submit-button {
  border: 0;
  background: $alternate-color;
  height: 35px;
  line-height: 1;
  border-radius: 18px;
  padding: 10px 18px;
  font-size: $title-size;
  color: #ffffff;
}
.del-button {
  border: 1px solid #cccccc;
  background: #ffffff;
  height: 35px;
  line-height: 1;
  border-radius: 18px;
  padding: 10px 18px;
  font-size: $title-size;
  color: #262626;
}
.bills-item-right-text {
  font-size: 13px;
  color: #666666;
}
// main-box
.main-box {
  background: #ffffff;
  padding: 15px 15px 8px;
  overflow: hidden;
  margin-top: 15px;
}
// supplement-box
.supplement-box {
  background: #ffffff;
  padding: 15px;
  overflow: hidden;
  margin-top: 15px;
}