@import "../../style/vars/index.scss";

.order-detail-bills-item {
  padding-right: 0px;
  .bills-item-main {
    top: 0px;
    .bills-item-feature {
      margin-bottom: 6px;
      padding-right: 0px;
    }
  }
}
// product-detail-normal
.am-list.product-detail-normal-custom-list {
  .am-list-body {
    &::before,
    &::after {
      content: none;
    }
  }
}
.am-list-item.product-detail-normal-custom-list-item {
  padding-left: 0;
  min-height: 36px;
  .am-list-line {
    padding-right: 0;
    $pd: 6px;
    .am-list-content {
      font-size: $content-size;
      font-weight: 500;
      color: #888888;
      padding-top: $pd;
      padding-bottom: $pd;
    }
    .am-list-extra {
      flex-basis: 75%;
      font-size: $content-size;
      font-weight: 500;
      color: #888888;
      padding-top: $pd;
      padding-bottom: $pd;
    }
  }
}
// product-detail-price
.am-list.product-detail-price-custom-list {
  .am-list-body {
    &::before,
    &::after {
      content: none;
    }
  }
}
.am-list-item.product-detail-price-custom-list-item {
  padding-left: 0;
  min-height: 36px;
  .am-list-line {
    padding-right: 0;
    $pd: 6px;
    .am-list-content {
      font-size: $title-size;
      font-weight: 500;
      color: #212121;
      padding-top: $pd;
      padding-bottom: $pd;
    }
    .am-list-extra {
      flex-basis: 75%;
      font-size: $content-size;
      font-weight: bold;
      color: $price-color;
      padding-top: $pd;
      padding-bottom: $pd;
    }
  }
}
// product-detail-supplement
.am-list.product-detail-supplement-custom-list {
  .am-list-body {
    &::before,
    &::after {
      content: none;
    }
  }
}
.am-list-item.product-detail-supplement-custom-list-item {
  padding-left: 0;
  min-height: 36px;
  .am-list-line {
    padding-right: 0;
    $pd: 6px;
    .am-list-content {
      font-size: 13px;
      font-weight: 500;
      color: #333333;
      padding-top: $pd;
      padding-bottom: $pd;
    }
    .am-list-extra {
      flex-basis: 15%;
      font-size: $content-size;
      font-weight: bold;
      color: $price-color;
      padding-top: $pd;
      padding-bottom: $pd;
    }
  }
}