@import "../vars/index";
@import "../mixins/index";

// 底部固定盒子
$fixedBottomBoxHeight: 60px;
.fixed-bottom-box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $fixedBottomBoxHeight;
  background: #ffffff;
  border-top: 1px solid #DFDFDF;
  @include flex-box(center);
  z-index: 10;
}
.fixed-bottom-box--blank {
  height: $fixedBottomBoxHeight;
}
// 猜你喜欢
.recommend-box {
  margin-top: 12px;
  background: #ffffff;
}
.recommend-title {
  @include flex-box(center);
  height: 60px;
  border-bottom: 1px solid #E5E5E5;
  font-size: $title-size;
  color: #414141;
  font-weight: bold;
}
// "去商场逛逛吧"按钮
.back-home-button {
  @include linear-button(300px, 45px);
  border: 1px solid $main-color;
  background: transparent;
  color: $main-color;
  font-size: 15px;
  margin-top: 52px;
  margin-bottom: 62px;
  &:hover {
    background: transparent;
  }
}
// animate
.bounceIn {
  animation: bounceIn 0.3s cubic-bezier(.215,.61,.355,1) forwards;
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(.3,.3,.3)
  }
  40% {
    transform: scale3d(.9,.9,.9)
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03,1.03,1.03)
  }
  to {
    opacity: 1;
    transform: scaleX(1)
  }
}
.scaleOut {
  animation: scaleOut 0.2s cubic-bezier(.215,.61,.355,1) forwards;
}
@keyframes scaleOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(0,0,0);
  }
}
// 评论图片多样尺寸
.pic--1x {
  //width: 97px;
  //height: 97px;
  width: 32%;
  height: 0px;
  padding-bottom: 32%;
}
.pic--2x {
  //width: 145px;
  //height: 145px;
  width: 48%;
  height: 0px;
  padding-bottom: 50%;
}
.pic--3x {
  width: 195px;
  height: 195px;
}
// 加载更多wrapper
.load-more-wrapper {
  margin: 30px;
}
// fab-box
$fabSize: 55px;
.fab-box {
  width: $fabSize;
  height: $fabSize;
  border-radius: 50%;
  box-shadow: 0px 1px 6px 0px rgba(156,156,156,0.26);
  background: #ffffff;
  @include flex-box(center);
}
.h_line {
  border-bottom: 1px solid;
  transform: scaleY(0.5);
}
.h_line {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border-color: #e5e5e5;
}