@import "../../style/mixins/index.scss";

$marginHack: 5px;
.info-box {
  @include flex-box(space-between);
  padding: 0 15px;
  height: 45px;
  background: #ffffff;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: $marginHack;
}
.info-box-left {
  font-size: 15px;
  font-weight: bold;
  color: #666666;
}
.info-box-right {
  font-size: 12px;
  color: #BFBFBF;
}
.comment-cate-list {
  margin: -$marginHack;
  padding: 15px;
  background: #ffffff;
}
.comment-list {
  margin-top: 12px;
  padding: 13px 15px;
  background: #ffffff;
}
.empty-image {
  margin-top: 100px;
}