@import "../../style/vars/index.scss";

.bill-list {
  .am-list-body::after {
    height: 2px !important;
    background-color: #E5E5E5 !important;
  }
}
.am-list-item.bill-list-item {
  padding-left: 0;
  .am-list-line {
    padding-right: 10px;
    $pd: 12px;
    .am-list-content {
      padding-top: $pd;
      padding-bottom: $pd;
    }
    .am-list-extra {
      flex-basis: 75%;
      font-size: $content-size;
      color: $alternate-color;
      padding-top: $pd;
      padding-bottom: $pd;
    }
  }
}
