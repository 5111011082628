@import "../vars/index.scss";

@mixin linear-button($width, $height) {
  display: block;
  width: $width;
  height: $height;
  border-radius: 22px;
  border: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: $title-size;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, $linear-color-from, $linear-color-to);
  &:hover {
    background: linear-gradient(90deg, $linear-color-to, $linear-color-from);
  }
}