@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

// edit-bar
.edit-bar {
  @include flex-box(space-between);
  height: 45px;
  background: #F6F6F6;
  padding: 0 15px;
}
.edit-bar-left {
  font-size: $content-size;
  color: #999999;
}
.edit-bar-right {
  font-size: 15px;
  color: #313131;
}
// carts
.carts {

}
.carts-item {
  height: 143px;
  background: #ffffff;
  padding: 0 15px;
  @include flex-box;
  &:not(:last-child) {
    margin-bottom: $margin-alternate;
  }
}
.carts-checked-png {
  align-self: center !important;
  margin-right: 11px !important;
  border-color: #aeaeae !important;
}
.carts-fixed-bottom-box {
  justify-content: flex-start !important;
  padding: 0 15px;
}
.bottom-checked-png {
  align-self: center !important;
  margin-right: 11px !important;
  border-color: #666666 !important;
}
.bottom-left {
  @include flex-box;
}
.bottom-main {
  @include flex-box(flex-end);
  flex: 1;
}
.bottom-main-left {
  @include flex-box;
  flex: 1;
}
.bottom-p1 {
  font-size: $title-size;
  color: #666666;
  margin-right: 12px;
}
.bottom-p2 {
  font-size: $title-size;
  color: #666666;
}
.bottom-p3 {
  font-size: 17px;
  color: $price-color;
  font-weight: bold;
}
.bottom-p3-yuan {
  font-size: $content-size;
}
.bottom-button {
  @include linear-button(117px, 44px);
  margin: 0;
  background: $alternate-color;
  &:hover {
    background: $alternate-color;
  }

}
// empty-box
.empty-box {
  background: #ffffff;
  overflow: hidden;
}
.empty-box-img {
  margin-top: 81px;
  margin-bottom: 27px;
}