@import "../../style/vars/index.scss";
@import "../../style/mixins/index.scss";

.info-box {
  height: 112px;
  background: #ffffff;
  padding: 18px 15px 14px;
  @include flex-box;
  overflow: hidden;
}
.goods-img {
  width: 80px;
  height: 80px;
  @include bg-image($bgSize: contain);
  border: 1px solid #DCDBDE;
  margin-right: 13px;
  position: relative;
}
.goods-count-tag {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #020202;
  opacity: 0.5;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
}
.info-text {
  font-style: $content-size;
  font-weight: bold;
  color: #262626;
  margin: 5px 0;
}
.info-status {
  color: #D69A51;
}
.step-list {
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 12px;
  padding: 24px 0px 53px;
}