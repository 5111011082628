@import "../../style/vars/index.scss";

$placeholder-color: #bfbfbf;
@mixin left-title() {
  font-size: $content-size;
  color: #666666;
}
@mixin right-text {
  font-size: $content-size;
  color: #262626;
}
.address-cell-box {
  .am-list-body::after {
    background-color: transparent !important;
  }
  .am-list-item {
    height: 55px;
    &.am-textarea-item {
      height: 94px;
    }
    .am-list-content {
      width: 85px;
      flex: none;
      @include left-title;
      margin-right: 5px;
    }
    .am-list-line {
      .am-list-extra {
        flex-basis: 100%;
        text-align: left;
        font-size: $content-size;
        color: $placeholder-color;
      }
    }
    .am-input-label {
      @include left-title;
    }
    .am-input-control {
      input {
        @include right-text;
        &::-webkit-input-placeholder {
          color: $placeholder-color;
        }
      }
    }
    .am-textarea-control {
      textarea {
        @include right-text;
        &::-webkit-input-placeholder {
          color: $placeholder-color;
        }
      }
    }
    .am-textarea-label {
      @include left-title;
    }
  }
  &.has-district-value {
    .am-list-line {
      .am-list-extra {
        color: #262626;
      }
    }
  }
}
.default-box {
  .am-list-body::before {
    background-color: transparent !important;
  }
  .am-list-item {
    height: 55px;
    .am-list-line {
      .am-list-content {
        font-size: $content-size;
        font-weight: bold;
        color: #333333;
      }
    }
    .am-switch {
      .checkbox {
        width: 50px;
        height: 25px;
        background: #D8D8D8;
        &::before {
          width: 47px;
          height: 23px;
          background: #D8D8D8;
        }
        &::after {
          height: 22px;
          width: 22px;
        }
      }
      input[type="checkbox"]:checked + .checkbox:after {
        transform: translateX(25px);
      }
    }
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .default-box .am-list-body::before {
    background-color: transparent;
  }
}