@import "../mixins/index.scss";

.checked-png {
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: $margin-alternate;
  align-self: flex-start;
  border: 1px solid #999999;
}
.checked-png--checked {
  border-width: 0px;
}