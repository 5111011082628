@import "../vars/index";

.k-tag {
  background: $price-color;
  padding: 2px 4px;
  border-radius: 7px;
  font-size: 11px;
  color: #ffffff;
}
// 默认地址
.default-tag {
  font-size: 11px;
  color: $alternate-color;
  background: rgba(214,160,94,0.09);
  border-radius: 4px;
  padding: 3px 5px;
}
// 评论分类标签
.comment-cate-tag {
  display: inline-block;
  box-sizing: border-box;
  padding: 8px 16px;
  border: 1px solid transparent;
  background: #F4F4F4;
  border-radius: 15px;
  color: #666666;
  font-size: 13px;
  line-height: 1;
  margin: 5px;
}
.comment-cate-tag--active {
  background: #FCF8F4;
  border-color: #E6AE6A;
  color: #E2A152;
}