@import "../../style/vars/index.scss";

.am-list.input-list {
  .am-list-body {
    &::before,
    &::after {
      content: none;
    }
  }
}
.am-list-item.input-list-item {
  background: #F7F7F7;
  min-height: 60px;
  border-radius: 9px;
  .am-input-label.am-input-label-5 {
    width: 30px;
  }
  .am-input-control input {
    font-size: 30px;
  }
}
.am-list.custom-list {
  .am-list-body {
    &::before,
    &::after {
      border-color: #e5e5e5;
    }
  }
}
.am-list-item.custom-list-item {
  padding-left: 0;
  .am-list-line {
    padding-right: 0;
    $pd: 14px;
    .am-list-content {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      padding-top: $pd;
      padding-bottom: $pd;
    }
    .am-list-extra {
      flex-basis: 75%;
      font-size: $title-size;
      font-weight: bold;
      color: $price-color;
      padding-top: $pd;
      padding-bottom: $pd;
    }
  }
}